<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-10 18:38:06
 * @LastEditTime: 2020-04-30 18:36:02
 -->
<template>
	<div class="app-container" style="position: relative">
		<el-tabs v-model="activeName">
			<el-tab-pane label="简历管理" name="1" lazy>
				<Interview ref="child1" :tagList="tagArr" @showDialog="_getDetail"></Interview>
			</el-tab-pane>
			<el-tab-pane label="备选" name="2" lazy>
				<Backup ref="child2" :tagList="tagArr" @showDialog="_getDetail"></Backup>
			</el-tab-pane>
		</el-tabs>
		<MoveDialog
			v-if="dialogStatus"
			:currentRow="detail"
			:visible.sync="dialogStatus"
			:title="titles"
			:classifyListNew="classifyList"
			@updateList="_updateList"
		></MoveDialog>
	</div>
</template>
<script>
import { tagList, classifyList } from '@/api/recruitment_allocation';
import Interview from './page/interview';
import Backup from './page/backup';
import MoveDialog from '../brainbank/components/moveDialog';
export default {
	name: 'resume',
	components: { Interview, Backup, MoveDialog },
	data() {
		return {
			activeName: '1',
			tagArr: [],
			dialogStatus: false,
			classifyList: [],
			detail: {},
			titles: '移动简历'
		};
	},
	computed: {},
	mounted() {
		this._getClassifyList();
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (data) {
			this.activeName = data.activeName;
		}
		tagList({}).then((res) => {
			if (res) {
				this.tagArr = res;
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	},
	methods: {
		_updateList() {
			this.$refs[`child${this.activeName}`] && this.$refs[`child${this.activeName}`]._getList();
		},
		_getClassifyList() {
			classifyList({}).then((res) => {
				this.classifyList = res;
			});
		},
		_getDetail(obj) {
			this.detail = obj.detail;
			this.titles = obj.title;
			this.dialogStatus = true;
		}
	},
	watch: {
		activeName(newVal) {
			this.$refs[`child${newVal}`] && this.$refs[`child${newVal}`]._getList();
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
</style>
