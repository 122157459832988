<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-15 18:04:03
 * @LastEditTime: 2020-04-30 17:55:11
 -->
<template>
  <div v-loading="loading">
    <el-form :model="searchForm" ref="searchForm" class="searchForm" inline size="small">
      <el-form-item label="性别">
        <el-select style="width: 160px;" v-model="searchForm.sex" size="small" clearable>
          <el-option v-for="(sex, i) in sexArr" :key="i" :label="sex.dictName" :value="sex.dictId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源渠道">
        <el-select style="width: 160px;" v-model="searchForm.sources" size="small" clearable>
          <el-option
            v-for="(sources, i) in sourcesArr"
            :key="i"
            :label="sources.dictName"
            :value="sources.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作年限">
        <el-select style="width: 160px;" v-model="searchForm.yearsWorking" size="small" clearable>
          <el-option
            v-for="(experience,i) in experienceArr"
            :key="i"
            :label="experience.dictName"
            :value="experience.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签">
        <el-select style="width: 160px;" size="small" v-model="searchForm.labelId" clearable>
          <el-option v-for="(tag,i) in tagArr" :key="i" :value="tag.id" :label="tag.tagName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投递时间">
        <el-date-picker
          v-model="searchForm.deliveryTimeBefore"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [00:00:00]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.deliveryTimeEnd"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [23:59:59]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
        <el-button type="primary" style="margin-left:5px" size="small" @click="_getList(1)" icon="el-icon-search">查询</el-button>
      <div style="float:right">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit"
          @click="$router.push({name:'EnterResume'})"
        >录入</el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-upload2"
          @click="$router.push({name:'ExportResume'})"
        >导入</el-button>
      </div>
    </el-form>
    <el-table :data="list" stripe border>
      <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
      <el-table-column label="基础信息" align="left" show-overflow-tooltip width="300">
        <template slot-scope="{row}">
          <p
            class="moryeBasic"
          >{{row.name}} <i :style="{color : row.sex=='1' ? '#498DF0' : '#FF7371'}">{{row.sex=='1'?'♂':'♀'}}</i> {{row.yearsWorking}}·{{row.highestEducationName}}</p>
          <p class="moryeBasic">{{row.phoneNumber}}/{{row.mailbox}}</p>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col,i) in columns"
        :prop="col.prop"
        :key="i"
        align="center"
        :label="col.label"
        width="200"
        show-overflow-tooltip
      >
        <template
          v-slot="{row}"
          v-if="col.prop == 'deliveryTime' || col.prop == 'moveTime'"
        >{{row[col.prop].slice(0,10)}}</template>
        <template
          v-slot="{ row }"
          v-else-if="col.prop == 'graduatedSchool'"
        >{{ row.graduatedSchool }}/{{ row.industry }}</template>
        <template
          v-slot="{ row }" v-else-if="col.prop == 'labelNames'"
        >
          <el-tag v-for="(item,index) in row.labelNames && (row.labelNames).split(',')" :key="index" style="margin-bottom: 5px;">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template v-slot="{row}">
          <el-dropdown>
            <el-button type="text"><img src="../../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, i) in commands"
                @click.native.stop="handleRowClick(row,item)"
                :command="item"
                v-show="showItem(row,item)"
                :key="i"
              >{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin:20px 0;text-align: right"
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.pageNo"
      :page-sizes="[20, 50, 100, 200, 300, 400]"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.totalNum"
    ></el-pagination>
  </div>
</template>
<script>
import { resumeList, resumeAlternative } from '@/api/recruitment_allocation';
export default {
  props: ['tagList'],
  data() {
    return {
      loading: false,
      dateRange: '',
      sourcesArr: [],
      experienceArr: [],
      sexArr: [],
      tagArr: this.tagList,
      list: [],
      searchForm: {
        status: 1,
        sex: '',
        sources: '',
        labelId: '',
        yearsWorking: '',
        deliveryTimeBefore: '',
        deliveryTimeEnd: '',
        pageNo: 1,
        pageSize: 20,
        totalNum: 0
      },
      commands: [
        { label: '简历详情', value: '5' },
        { label: '简历编辑', value: '6' },
        { label: '备选', value: '1' },
        {label: '移动', value: '2' },
        {label: '安排面试', value: '3' },
        {label: '打印简历', value: '4' }
      ],
      columns: [
        { label: '毕业院校/专业', prop: 'graduatedSchool' },
        { label: '应聘岗位', prop: 'jobName' },
        { label: '投递渠道', prop: 'sources' },
        { label: '标签', prop: 'labelNames' },
        { label: '投递时间', prop: 'deliveryTime' }
      ]
    };
  },
  methods: {
    _handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this._getList();
    },
    _handleCurrentChange(val) {
      this.searchForm.pageNo = val;
      this._getList();
    },
    _getList(page) {
      page ? this.searchForm.pageNo = page : '';
      this.loading = true;
      resumeList(this.searchForm).then((res) => {
        this.loading = false;
        this.list = res.list;
        this.searchForm.totalNum = res.totalNum;
      });
    },
    _dateRangeChange(val) {
      if (val) {
        this.searchForm.deliveryTimeBefore = val[0];
        this.searchForm.deliveryTimeEnd = val[1];
      } else {
        this._clear();
      }
    },
    _clear() {
      this.dateRange = '';
      this.searchForm.deliveryTimeBefore = '';
      this.searchForm.deliveryTimeEnd = '';
    },
    handleRowClick(row, item) {
      switch (item.value) {
        case '1': {
          this.$confirm(`确认将${row.name}放入备选?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              resumeAlternative({ id: row.id }).then((res) => {
                if (res.code === 0) {
                  this._getList();
                  this.$message.success('备选成功');
                }
              });
            })
            .catch(() => {});
          break;
        }
        case '2': {
            this.$emit('showDialog', {detail: row, title: '移动简历'});
          break;
        }
        case '3': {
          this.$router.push({
            name: 'ScheduleInterview',
            params: { resumeId: row.id }
          });
          break;
        }
        case '4': {
          this.$router.push({ name: 'RecruitPrint', params: { id: row.id } });
          break;
        }
        case '5': {
          this.$router.push({ name: 'ResumeInfo', params: { id: row.id } });
          break;
        }
        case '6': {
          this.$router.push({ name: 'EditResume', params: { id: row.id } });
          break;
        }
      }
    },
    showItem(row, item) {
      if (this.index == 2 && item.value == 1) {
        return false;
      } if (row.stationStatus != 1 && item.value == 3) {
        return false;
      }
        return true;

    }
  },
  mounted() {
    this.sexArr = this.$store.state.app.dict.filter((item) => item.groupId == 'sex');
    this.sourcesArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
    this.experienceArr = this.$store.state.app.dict.filter((item) => item.groupId == 'experience');
    this._getList();
  },
  watch: {
    tagList: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.tagArr = newVal;
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.moryeBasic {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
